import { makeApiRequest } from '@/src/lib/api-request'
import { useUser } from '@/src/store/user-store'
import { useMutation } from '@tanstack/react-query'

export const useLogoutMutation = () => {
  const { refreshToken } = useUser()
  return useMutation({
    mutationKey: ['user', 'logout'],
    mutationFn: async () => {
      await makeApiRequest({
        method: 'POST',
        url: '/User/logoutuser',
        data: {
          refresh_token: refreshToken,
        },
        isAuthenticated: true,
        fallbackError: 'Error while logging out',
        isV3Api: true,
      })
    },
  })
}
