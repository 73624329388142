import { makeApiRequest } from '@/src/lib/api-request'
import { useQuery } from '@tanstack/react-query'
import { useLocationQuery } from '../guest/location-query'
import { useUser } from '@/src/store/user-store'
import { useToken } from '@/src/store/token-store'

interface SearchOnTypeDto {
  description: string
  country: string
  city: string
  ip: string
}

export const useSearchQuery = () => {
  const { data, isFetched: isLocationFetched } = useLocationQuery()
  const { user, _hasHydrated } = useUser()
  const { accessToken } = useToken()
  return useQuery({
    queryKey: ['search', { user }, data?.city, data?.country],
    queryFn: async () => {
      const response = await makeApiRequest({
        method: 'POST',
        url: user
          ? '/Landing/get_Search_Recomandation_withLogin'
          : '/Landing/get_Search_Recomandation_withoutLogin',
        isAuthenticated: user ? true : false,
        data: user
          ? {}
          : {
            city: data?.city || '',
            country: data?.country || '',
          },
        sendCredentials: true,
        fallbackError: 'Error while fetching search results',
        isV3Api: true,
      })
      const res = response.response.data as [{ description: string, sreachType: string }]
      return res.filter((item) => item.description.trim() !== '')
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: !!(isLocationFetched && (!user || !!accessToken) && _hasHydrated)
  })
}

export const useSearchOnTypeQuery = (debouncedQuery: string) => {
  const { user, _hasHydrated } = useUser()
  const { data: locationData, isFetched: isLocationFetched } = useLocationQuery()
  const { accessToken } = useToken()
  return useQuery({
    queryKey: [
      'search-type',
      debouncedQuery,
      user,
      locationData?.city,
      locationData?.country,
      locationData?.ip
    ],
    queryFn: async () => {
      const dataProps: SearchOnTypeDto = {
        description: debouncedQuery,
        city: locationData?.city || '',
        country: locationData?.country || '',
        ip: locationData?.ip || '',
      }
      const response = await makeApiRequest({
        method: 'POST',
        url: user
          ? '/Landing/getSearchText_withLogin'
          : '/Landing/getSearchText_withoutLogin',
        isAuthenticated: user ? true : false,
        data: dataProps,
        fallbackError: 'Error while fetching search results',
        isV3Api: true
      })

      const res = response.response.data as [{ description: string }]
      return res.filter((item) => item.description.trim() !== '')
    },
    refetchOnWindowFocus: false,
    enabled: !!isLocationFetched && (!user || !!accessToken) && !!debouncedQuery && _hasHydrated,
  })
}
