import { makeApiRequest } from '@/src/lib/api-request'
import { useQuery } from '@tanstack/react-query'

export interface Interest {
  interestId: string
  interest: string
  interestImage: string
  isSubInterest: string
  isDelete: string
  serial_Id: string
}

export const useInterestQuery = () => {
  return useQuery({
    queryKey: ['interests'],
    queryFn: async () => {
      const response = await makeApiRequest({
        method: 'GET',
        url: '/UnregisterUser/getInterest',
        isAuthenticated: false,
        fallbackError: 'Error while fetching interests',
        data: {},
        isV3Api: true,
      })

      const data: Interest[] = response.response;
      return data as Interest[]
    },
    refetchOnWindowFocus: false,
    staleTime : Infinity,
  })
}
