import { makeApiRequest } from '@/src/lib/api-request'
import { useQuery } from '@tanstack/react-query'
import { useUser } from '@/src/store/user-store'
import { useToken } from '@/src/store/token-store'

interface Balance {
  INR: string
  currency: string
  lykCoin: string
  moneyValue: string
}

interface WalletBalanceResponse {
  verified: Balance
  unverified: Balance
  unVerifiedRedeem: Balance
}

export const useWalletBalanceQuery = () => {
  const { user, _hasHydrated } = useUser()
  const { accessToken } = useToken()
  return useQuery({
    queryKey: ['wallet-balance', user],
    queryFn: async () => {
      const response = await makeApiRequest({
        method: 'GET',
        url: '/Earning/getCurrentLykCoin',
        isAuthenticated: true,
        data: {},
        fallbackError: 'Error while fetching user balance',
        isV3Api: true,
      })

      return response.response as WalletBalanceResponse
    },
    enabled: !!_hasHydrated && !!accessToken,
    refetchOnWindowFocus: false,
  })
}
